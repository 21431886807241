<template>
    <div class="container PLR_3 H_100vh bg_fff">
        <div class="title">
            <van-icon name="arrow-left" @click="$router.go(-1)" size=".35rem" />
            <div style="margin-left:2.3rem;">创建技能</div>
        </div>
        <!-- <div class="PL_2 textL PB_3 PT_2 fontS_17 font_weight_bold">推荐技能</div> -->
        <div class="tjList">
            <div class="tjItem" @click="addTag(item)" v-for="(item,index) in hot" :key="index">{{item.position_name}}</div>
        </div>
        <div class="PL_2 MT_4 textL PB_3 PT_2 fontS_17 font_weight_bold">新增自定义技能</div>
        <div class="zdy">
            <input v-model="zdyname" class="input" type="text" placeholder="请输入自定义技能名称">
            <img @click="addzdy" src="../../assets/jai.png" alt="">
        </div>
        <div class="PL_2 MT_4 textL PB_3 PT_2 fontS_17 font_weight_bold">
            请选择技能类型
        </div>
        <div class="PLR_2">
            <div @click="goChoiceList(gzid)" class="PTB_4 BB_1_D6D6D6 flex_row_between">
                <span>工作技能</span>
                <van-icon name="arrow" size=".35rem" />
            </div>
            <div @click="goChoiceList(shid)" class="PTB_4 BB_1_D6D6D6 flex_row_between">
                <span>生活技能</span>
                <van-icon name="arrow" size=".35rem" />
            </div>
            <!-- <div @click="goChoiceList(qtid)" class="PTB_4 BB_1_D6D6D6 flex_row_between">
                <span>其他技能</span>
                <van-icon name="arrow" size=".35rem" />
            </div> -->
        </div>
    </div>
</template>

<script>
    import '@/assets/css/common.css';
import { Toast, Dialog } from 'vant';

    export default {
        name: "",
        data() {
            return {
                title: '',
                zdyname:"",
                hot:[],
                gzid:"",
                shid:"",
                qtid:""
            }
        },
        created() {
        },
        mounted() {
        //    let id = sessionStorage.getItem("id")
        //    this.$http.post("/user/v1/Position/getHot",{reqType:"getHot",user_id:id})
        //    .then((res)=>{
        //       res = JSON.parse(res.data)
        //       console.log(res)
        //       if(res.code == 0){
        //           console.log(res)
        //           this.hot = res.data.hot
        //       }else{
        //           Toast.fail(res.msg)
        //       }
        //    })
        //    .catch((e)=>{console.log(e)})

           this.$http.post("/user/v1/Position/positionList",{reqType:"positionList",pid:""})
           .then((res)=>{
               res = JSON.parse(res.data)
            //    console.log(res)
               res.data.forEach(element => {
                if(element.position_name == "工作技能"){
                    this.gzid = element.positionId
                }else if(element.position_name == "生活技能"){
                    this.shid = element.positionId
                }else{
                    this.qtid = element.positionId
                }
                
               });
              
           })
           .catch((e)=>{
               console.log(e)
           })

        },
        methods: {
            addTag(item){
                Dialog.confirm({
                    title: '标题',
                    message: '确认新增该技能?',
                })
                .then(() => {
                    // on confirm
                    let  userId = sessionStorage.getItem("id")
                    let  { type,position_name,id } = item
                    // console.log(type,position_name,id,userId)
                    this.$http.post("/user/v1/Position/userSkill",{reqType:"userSkill",user_id:userId,skill_type:type,skill_name:position_name,mim_id:0,status:2,skill_id:id})
                    .then((res)=>{
                        res = JSON.parse(res.data)
                        // console.log(res)
                        if(res.code == 0){
                            Toast.success("新增成功")
                            setTimeout(()=>{
                                this.$router.push("./onlineTag")
                            },1000)
                        }else{
                            Toast.fail(res.msg)
                        }
                    })
                    .catch((e)=>{console.log(e)})
                })
                .catch(() => {
                    // on cancel
                });
                
            },
            goChoiceList(id){
                if(id == 1){
                    localStorage.setItem("typeId",id)
                    this.$router.push({path:"/addTagItem",query:{id:id}})
                }else{
                    Toast.fail("暂无数据")
                }
            
            },
            goTo() {
                this.$router.push('/tagList');
            },
            addzdy(){
                let zdyname = this.zdyname
                let user_id = localStorage.getItem("id")
                if(zdyname == ""){
                    Toast("请输入技能名称")
                    return
                }
                Dialog.confirm({
                    title: '标题',
                    message: '确认新增该技能?',
                })
                .then(() => {
                    // on confirm
                    this.$http.post("/user/v1/Position/positionAdd",{reqType:"positionAdd",user_id:user_id,position_name:zdyname})
                    .then((res)=>{
                        res = JSON.parse(res.data)
                        if(res.code == 0){
                            Toast.success(res.msg)
                            this.zdyname = ""
                            setTimeout(()=>{
                                this.$router.push("./onlineTag")
                            },500) 
                        }else{
                            Toast.fail(res.msg)
                        }
                        
                    })
                    .catch((e)=>{console.log(e)})
                    })
                .catch(() => {
                    // on cancel
                });


               
                console.log(zdyname)
            }
        },
        components: {

        }
    }
</script>

<style scoped>
.title{
    display:flex;
    align-items: center;
}
.zdy img{
    width:0.4rem; height:0.4rem;
    position: absolute;
    right:0.3rem;
    top:0.1rem;
}
.zdy{
    position:relative;
    text-align: left;
    border-bottom: 0.01rem solid #ddd;
    padding-bottom:0.2rem;
    margin:0 0.16rem;
}
.input{
    height:0.73rem;
    width:4rem;
    font-size: 0.28rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #686868;
    /* background-color: #F44C06; */
}
.container{
  position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
	text-align: center;
  }
.title{
    font-size: 0.42rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    padding:0.4rem 0;
}
.tjList{
    display:flex;
    flex-wrap: wrap;
    margin-top:0.1rem;
    margin-bottom:0.5rem;
}
.tjItem{
    margin-bottom:0.15rem;
    background: #FFEDE4;
    border-radius: 0.28rem;
    padding:0.15rem 0.3rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #F44C06;
    margin-right:0.2rem;
}
</style>